<template>
  <b-card>
    <div
      v-if="!loading"
      class=" text-center my-2"
    >
      <b-spinner
        type="grow"
        label="Loading..."
      />
    </div>
    <b-card v-else>
      <validation-observer
        ref="infoRules"
        v-slot="{ invalid }"
        tag="form"
      >
        <b-row>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="From"
              rules="required"
            >
              <b-form-group
                label="From"
                label-for="from"
              >
                <b-form-input
                  id="from"
                  v-model="autoReportForm.from"
                  :state="getValidationState(validationContext)"
                  type="date"
                  trim
                  placeholder="From"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="To"
              rules="required"
            >
              <b-form-group
                label="To"
                label-for="to"
              >
                <b-form-input
                  id="to"
                  v-model="autoReportForm.to"
                  :state="getValidationState(validationContext)"
                  type="date"
                  trim
                  placeholder="To"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="report_name"
              rules="required"
            >
              <b-form-group
                label="Report Name"
                label-for="Report Name"
              >
                <b-form-input
                  id="report_name"
                  v-model="autoReportForm.report_name"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="report_name"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <!-- {{ reportStatus }} -->
        <b-row>
          <b-col md="12">
            <div class="d-flex justify-content-between w-100">
              <h2>Fields</h2>
              <b-form-checkbox
                id="select-all-fields"
                v-model="selectAll"
                class="select-all-checkbox"
                @change="toggleSelectAll"
              >
                <span class="text-primary text-bold">Select All</span>
              </b-form-checkbox>
            </div>

            <b-form-checkbox-group
              id="main"
              v-model="autoReportForm.main"
              class="d-flex flex-wrap"
              :options="mainOptions"
              value-field="item"
              text-field="name"
              multiple
            />
            <b-form-checkbox-group
              id="fields"
              v-model="autoReportForm.fields"
              class="d-flex flex-wrap"
              :options="fieldsOptions"
              value-field="item"
              text-field="name"
              multiple
            />
            <b-form-checkbox-group
              id="requirements"
              v-model="autoReportForm.requirements"
              class="d-flex flex-wrap"
              :options="requirementsOptions"
              value-field="item"
              text-field="name"
              multiple
            />

          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              v-if="!loader"
              variant="primary"
              class="mr-1"
              :disabled="invalid || Object.values(errors).length > 0"
              @click="generateReport()"
            >
              Generate
            </b-button>
            <b-button
              v-if="loader"
              variant="primary"
              disabled
              class="mr-1"
            >
              <b-spinner
                small
                type="grow"
              />
              Loading...
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-card>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import { required } from '@validations'
// eslint-disable-next-line no-unused-vars
import _ from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import decryptData from '@/store/auth/decryptData'

export default {
  components: {},
  data() {
    return {
      required,
      loader: false,
      loading: true,
      validationErrors: {},
      errors: {},
      reportStatus: '',
      mainOptions: [],
      fieldsOptions: [],
      requirementsOptions: [],
      selectAll: false, // State for the "Select All" checkbox
    }
  },

  setup() {
    const { getValidationState } = formValidation()
    // eslint-disable-next-line camelcase

    const autoReportForm = ref({
      from: '',
      to: '',
      report_name: '',
      main: [],
      fields: [],
      requirements: [],
    })
    return {
      getValidationState,
      autoReportForm,
    }
  },

  watch: {
  // Watch for changes in any of the groups to toggle "Select All" dynamically
    autoReportForm: {
      deep: true,
      handler() {
        const allSelected = this.autoReportForm.main.length === this.mainOptions.length
        && this.autoReportForm.fields.length === this.fieldsOptions.length
        && this.autoReportForm.requirements.length === this.requirementsOptions.length

        // Update "Select All" state based on the current selection
        this.selectAll = allSelected
      },
    },
  },
  mounted() {
    this.reportStatus = this.$route.params.name
    this.getfields(this.reportStatus)
    this.getTemplatesFields()
  },
  methods: {
    toggleSelectAll() {
      if (this.selectAll) {
      // When "Select All" is checked, populate all options
        this.autoReportForm.main = this.mainOptions.map(option => option.item)
        this.autoReportForm.fields = this.fieldsOptions.map(option => option.item)
        this.autoReportForm.requirements = this.requirementsOptions.map(option => option.item)
      } else {
      // When "Select All" is unchecked, clear all selections
        this.autoReportForm.main = []
        this.autoReportForm.fields = []
        this.autoReportForm.requirements = []
      }
    },
    getfields(statu) {
      if (this.$route.params.name) {
        this.loading = false
        let baseFields = ''
        if (statu) {
          baseFields = statu
        }
        axios.get(`auto-generate-report/${baseFields}/fields`).then(res => {
          if (res.status === 200) {
            this.loading = true
            const userData = decryptData(res.data.payload).data
            // const fieldsMap = {
            //   productDescriptionEn: 'product',
            // }
            const main = userData.main.map(item => item)
            this.mainOptions = main.map(item => ({
              item,
              name: item.replaceAll('_', ' ').replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase(),
            }))
            const fields = userData.fields.map(item => item)
            this.fieldsOptions = fields.map(item => ({
              item,
              name: item.replaceAll('_', ' ').replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase(),
            }))
            const requirements = userData.requirements.map(item => item)
            this.requirementsOptions = requirements.map(item => ({
              item,
              name: item.replaceAll('_', ' ').replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase(),
            }))
          }
        })
      } else {
        return false
      }
      return true
    },
    getTemplatesFields() {
      if (this.$route.params.id) {
        this.loading = false
        axios.get(`report-template/${this.$route.params.id}`).then(res => {
          if (res.status === 200) {
            this.loading = true
            const templateData = decryptData(res.data.payload).data.report_template
            this.autoReportForm.report_name = templateData.report_name
            this.autoReportForm.main = templateData.report_data.main
            this.autoReportForm.fields = templateData.report_data.fields
            this.autoReportForm.requirements = templateData.report_data.requirements
          }
        })
      } else {
        return false
      }
      return true
    },
    generateReport() {
      this.loader = true
      const formData = new FormData()

      Object.keys(this.autoReportForm).forEach(key => {
        if (['main', 'fields', 'requirements'].includes(key)) {
          if (this.autoReportForm[key].length > 0) {
            this.autoReportForm[key].forEach(item => {
              formData.append(`${key}[]`, item)
            })
          } else {
            formData.append(`${key}[]`, [])
          }
        } else {
          formData.append(key, this.autoReportForm[key])
        }
      })

      axios
        .post(`auto-generate-report/${this.reportStatus}/report`, formData, {
          responseType: 'blob',
        })
        .then(res => {
          if (res.status === 200) {
            const fileName = `${this.autoReportForm.report_name}`
            const blob = new Blob([res.data], { type: res.data.type })
            const url = window.URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = url
            link.download = fileName
            link.click()
            this.$router.push({ name: 'auto-generate-report' })
            this.$toasted.show('Updated Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        })
        .catch(error => {
          if (error.response && error.response.data instanceof Blob) {
            const reader = new FileReader()
            reader.onload = () => {
              try {
                const responseText = reader.result
                const { payload } = JSON.parse(responseText)
                const finallyErrors = decryptData(payload).message
                const firstArrayKey = Object.keys(finallyErrors).find(key => Array.isArray(finallyErrors[key]))
                const errorMessage = firstArrayKey ? finallyErrors[firstArrayKey][0] : 'An unknown error occurred'

                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: errorMessage,
                    icon: 'BellIcon',
                    variant: 'error',
                  },
                })
              } catch (e) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: `${e} An error occurred while processing the error message.`,
                    icon: 'BellIcon',
                    variant: 'error',
                  },
                })
              }
            }
            reader.readAsText(error.response.data)
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'An unknown error occurred.',
                icon: 'BellIcon',
                variant: 'error',
              },
            })
          }
        })
        .finally(() => {
          this.loader = false
        })
    },

  },

}
</script>

  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  .custom-control{
    min-width: 350px;
    padding-bottom: 10px!important;
  }
  .select-all-checkbox   {
    label{
      span {
        font-size: 20px !important;
      }
    }
  }
  .select-all-checkbox label::before,
  .select-all-checkbox label::after{
    width: 25px !important;
    height: 25px !important;
  }
  </style>
